import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

/* Based on www.gatsbyjs.com/plugins/gatsby-background-image/#how-to-use-with-multiple-images
Styled component to add an element with a photo background and optional gradients over them

It's size is determined in a parent component where we can style it e.g. by Tag defined in BackgroundImage

To use it we can do:
import MultipleBackground from ...

<MultipleBackground
            img={backgroundImage.asset.fluid}  //path to fluid image
            // optional gradients array
            onTopGradients={[
              `linear-gradient(to left, transparent 20%, var(--grayDark) 100%)`,
              `linear-gradient(to right, transparent 20%, var(--grayDark) 100%)`,
            ]}
          ></MultipleBackground>

Handles the situation when there's no image passed (e.g. is not defined in CMS) 
  - then it uses default background (now it is transparent pixel)
  - whein there's is neither img and default background file - is also handled - shows warn in the console
*/

// query for default background
const getDefaultBackground = graphql`
  query {
    defaultBgImage: file(relativePath: { eq: "transparent-pixel.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const MultiBackground = ({ className, img, onTopGradients }) => {
  const { defaultBgImage } = useStaticQuery(getDefaultBackground)

  // Watch out for CSS's stacking order, especially when styling the individual
  // positions! The lowermost image comes last! - in backgroundFluidImageStack

  // if no image passed - render default background (retreived from query)
  // if passed image is destructurized fluid (e.g. backgroundImage.asset.fluid, not just backgroundImage)
  let backgroundFluidImageStack = [
    img || defaultBgImage?.childImageSharp?.fluid,
  ]

  // if given gradients add them to the array
  onTopGradients &&
    onTopGradients.length > 0 &&
    (backgroundFluidImageStack = backgroundFluidImageStack.concat(
      onTopGradients
    ))

  backgroundFluidImageStack.reverse()

  // handling if not given img & there's no present default background file in the project
  if (!(img || defaultBgImage?.childImageSharp?.fluid)) {
    console.warn('No default background image!')
    return <></>
  }

  return (
    <BackgroundImage
      Tag="div"
      id="test"
      className={className}
      fluid={backgroundFluidImageStack}
    />
  )
}

const StyledMultiBackground = styled(MultiBackground)`
  width: 100%;
  height: 100%;
  /* You should set a background-size as the default value is "cover"! */
  background-size: cover;
  /* So we won't have the default "lightgray" background-color. */
  background-color: transparent;
  /* Now again, remember the stacking order of CSS: lowermost comes last! */
  /* I use only one background-position and it works */
  background-repeat: no-repeat;
  background-position: 50% 20%;
  color: transparent;
`

export default StyledMultiBackground
